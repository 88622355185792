import React from 'react';
import PedalWrapper from '../../components/PedalWrapper/PedalWrapper';
import ShowLatestMusic from '../../components/ShowLatestMusic/ShowLatestMusic';
import useGet from './hooks/useGet';

const ShowCoolMusic: React.FC = () => {
	const [trackies, isLoading] = useGet('getrecenttracks');

	return (
		<>
			{isLoading ? (
				'Loading'
			) : (
				<PedalWrapper>
					<ShowLatestMusic trackies={trackies} />
				</PedalWrapper>
			)}
		</>
	);
};

export default ShowCoolMusic;

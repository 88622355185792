import React from 'react';
import { IRecentTrackTracks } from '../../types/IRecentTracksData';
import styles from './TrackieBoxxx.module.scss';

interface IProps {
	trackie: IRecentTrackTracks;
	size: 'small' | 'large';
}

const TrackieBoxxx: React.FC<IProps> = ({ trackie, size }) => {
	const boxSize = size === 'small' ? '300px' : '33%';

	return (
		<div className={styles.box} style={{ width: boxSize }}>
			<img
				alt={`Album cover for ${trackie.artist['#text']}, ${trackie.album['#text']}`}
				src={trackie.image[3]['#text']}
			/>
			<div>
				<p>{trackie.name}</p>
				<p>
					<i>{trackie.album['#text']}</i>
				</p>
				<p>
					<strong>{trackie.artist['#text']}</strong>
				</p>
			</div>
		</div>
	);
};

export default TrackieBoxxx;

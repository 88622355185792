import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import ShowCoolMusic from './views/ShowCoolMusic/ShowCoolMusic';

const App: React.FC = () => {
	return (
		<Router basename="/pedal2022">
			<Routes>
				<Route path="/" element={<ShowCoolMusic />} />
			</Routes>
		</Router>
	);
};

export default App;

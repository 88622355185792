import React, { useEffect, useState } from 'react';
import { words } from '../../stuff/words';
import { IRecentTracksData } from '../../types/IRecentTracksData';
import TrackieBoxxx from '../TrackieBoxx/TrackieBoxxx';

interface IProps {
	trackies: IRecentTracksData;
}

const ShowLatestMusic: React.FC<IProps> = ({ trackies }) => {
	const [pedal, setPedal] = useState('PEDAL');
	useEffect(() => {
		const interval = setInterval(() => {
			setPedal(words[Math.floor(Math.random() * words.length)]);
		}, 5000);
		return () => clearInterval(interval);
	});

	return (
		<div
			style={{
				maxWidth: '2000px',
				margin: '0 auto',
				background: 'white',
				textAlign: 'center',
			}}
		>
			<h1 style={{ margin: 0, padding: '60px' }}>{pedal}</h1>
			<div>
				<div style={{ display: 'flex', justifyContent: 'center' }}>
					<TrackieBoxxx trackie={trackies.recenttracks.track[0]} size="large" />
				</div>
				<div
					style={{
						display: 'flex',
						flexWrap: 'wrap',
						justifyContent: 'space-around',
					}}
				>
					{trackies.recenttracks.track.slice(1).map((trackie, i) => (
						<TrackieBoxxx trackie={trackie} key={i} size="small" />
					))}
				</div>
			</div>
		</div>
	);
};

export default ShowLatestMusic;

import React, { useEffect, useState } from 'react';
import { words as strings } from '../../stuff/words';
import classes from './WrapperSide.module.scss';

interface IProps {
	side: 'left' | 'right';
}

const WrapperSide: React.FC<IProps> = ({ side }) => {
	const sides = () => {
		let i = 0;
		const pedal: string[] = [];
		while (i < 1000) {
			i++;
			if (i % 4 === 0) {
				let randomString = strings[Math.floor(Math.random() * strings.length)];
				pedal.push(randomString);
			} else {
				pedal.push('PEDAL');
			}
		}
		return pedal;
	};
	const [test, setTest] = useState(sides());

	useEffect(() => {
		setInterval(() => {
			setTest(sides);
		}, 5000);
	}, []);

	return (
		<div
			className={classes.side}
			style={{
				left: side === 'left' ? 'calc(-100% + 16px)' : '-12px',
				right: side !== 'left' ? 'calc(-100% + 16px)' : '-12px',
			}}
		>
			<span className={classes.pedal}>{test}</span>
		</div>
	);
};
export default WrapperSide;

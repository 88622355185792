import { IRecentTracksData } from './../../../types/IRecentTracksData';
import axios from "axios";
import { useEffect, useState } from "react";

function useGet(endpoint: string, limit: number = 49): [IRecentTracksData, boolean] {
    const [isLoading, setIsLoading] = useState(true);
    const [response, setResponse] = useState<any>();


    useEffect(() => {
        const get = async () => {
            const userName = 'joisak';
            const key = '2716701230e056321ef598407d964f0b';
            const baseUrl = 'http://ws.audioscrobbler.com/2.0/';
            const url = `${baseUrl}?method=user.${endpoint}&user=${userName}&api_key=${key}&limit=${limit}&format=json`;

            try {
                const axiosResp = await axios.get(url);
                if (axiosResp.status === 200) {
                    setResponse(axiosResp.data);
                } else {
                    throw Error('Error');
                }
            } catch (error) {
                console.log(error);
            } finally {
                setIsLoading(false);
            }

        }

        if (!response) {
            get();
        }

    }, [endpoint, response, limit]);

    return [response, isLoading]
}

export default useGet;
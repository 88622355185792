import React, { ReactElement } from 'react';
import WrapperSide from '../WrapperSide/WrapperSide';
import classes from './PedalWrapper.module.scss';

interface IProps {
	children: ReactElement;
}

const PedalWrapper: React.FC<IProps> = ({ children }) => {
	return (
		<>
			<WrapperSide side="left" />
			<WrapperSide side="right" />
			<div className={classes.root}>{children}</div>
		</>
	);
};

export default PedalWrapper;
